/* You can add global styles to this file, and also import other style files */

// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './theme.scss';

* {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  // outline: 1px solid red;
}

html, body { height: 100%; }
body {
   margin: 0; 
   font-family: Roboto, "Helvetica Neue", sans-serif;
  }

.no-shadow {
  box-shadow: none !important;
}

.space {
  margin-left: 4px;
}